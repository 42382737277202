/* eslint-disable  */
import React, { useEffect, useState } from "react";
import RenderHome from "../home/RenderHome";
import "./FundIncrement.css";
import { bindActionCreators } from "redux";
import { userDataActionCreators } from "../../../service/action-creators";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { ToastContainer, toast } from "react-toastify";
import { Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import moment from "moment";

function FundAdjustment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { IncreaseFund, DecreaseFund, ResetUserDataResponse, GetMyIncreaseFund, GetMyDecreaseFund } =
    bindActionCreators(userDataActionCreators, dispatch);

  const [fundType, setFundType] = useState("");
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});

  const user = useSelector((state) => state.user);
  const { isLoading, response, fund_increment, fund_decrement } = user;
  const auth = useSelector((state) => state?.auth);

  const handleFundTypeChange = (e) => {
    setFundType(e.target.value);
  };

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validateForm();
    if (isValid) {
      if (fundType == 'increment') {
        IncreaseFund({
          ...formData,
          staff_id: auth?.user?.staff_id,
          // adjustment_type: fundType,
        });
      } else {
        DecreaseFund({
          ...formData,
          staff_id: auth?.user?.staff_id,
          // adjustment_type: fundType,
        });
      }
    }
  };

  const validateForm = () => {
    let err = {};
    if (!formData.name) err.name = "Please enter the name on your payslip";
    if (!formData.phone_number) err.phone_number = "Phone number is required";
    if (!formData.facility) err.facility = "Facility is required";
    if (!formData.mandate_number)
      err.mandate_number = "Mandate number is required";
    if (!formData.dob) err.dob = "Date of birth is required";
    if (!formData.next_of_kin) err.next_of_kin = "Next of kin is required";
    if (!formData.IDCardNumber) err.IDCardNumber = "ID card number is required";
    if (!formData.current_contribution)
      err.current_contribution = "Current contribution is required";
    if (!formData.requested_increment)
      err.requested_increment = "Requested amount is required";

    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  useEffect(() => {
    dispatch(GetUserProfile());
    GetMyIncreaseFund();
    GetMyDecreaseFund();
  }, []);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      GetMyIncreaseFund();
      GetMyDecreaseFund()
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "ERROR") {
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state]);

  return (
    <RenderHome>
      <div className="FundIncrement">
        {isLoading && <Loader />}
        <h1 className="pageTitle"> Fund Increment / Decrement</h1>

        <div className="fund-main">
          <div className="fund-container">
            <div className="form-group row mb-4 ">
              <div className="col-xs-12 col-sm-12 col-md-12 ">
                <label htmlFor="fundType">
                  Please select type of Fund to Increase / Decrease{" "}
                  <span className="bto-lb-required">*</span>
                </label>
                <select
                  className="form-control"
                  name="fundType"
                  value={fundType}
                  onChange={handleFundTypeChange}
                >
                  <option value="">Select type</option>
                  <option value="increment">Increment</option>
                  <option value="decrement">Decrement</option>
                </select>
              </div>
            </div>

            {fundType && (
              <div className="pageWrapper">
                <form>
                  <div className="form-group row mb-2">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>
                        Name (As appears on payslip){" "}
                        <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        aria-required="true"
                        onChange={handleFormChange}
                      />
                      <span className="error-message">{formError.name}</span>
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="staff_id">
                        Staff ID<span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="staff_id"
                        id="staff_id"
                        required
                        readOnly
                        defaultValue={auth?.user?.staff_id}
                        onChange={handleFormChange}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="phone_number">
                        Phone Number<span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        required
                        onChange={handleFormChange}
                      />
                      <span className="error-message">
                        {formError.phone_number}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>
                        Facility <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="facility"
                        aria-required="true"
                        onChange={handleFormChange}
                      />
                      <span className="error-message">
                        {formError.facility}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <label htmlFor="dob">
                        Date of Birth<span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        name="dob"
                        id="dob"
                        required
                        onChange={handleFormChange}
                      />
                      <span className="error-message">{formError.dob}</span>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <label htmlFor="mandate_number">
                        Mandate Number<span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="mandate_number"
                        id="mandate_number"
                        required
                        onChange={handleFormChange}
                      />
                      <span className="error-message">
                        {formError.mandate_number}
                      </span>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <label htmlFor="next_of_kin">
                        Next of Kin<span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="next_of_kin"
                        id="next_of_kin"
                        required
                        onChange={handleFormChange}
                      />
                      <span className="error-message">
                        {formError.next_of_kin}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="IdCardType">
                        ID Type<span className="bto-lb-required">*</span>
                      </label>
                      <select
                        name="IdCardType"
                        id="IdCardType"
                        value={"Ghana National Identification"}
                        className="form-control"
                        onChange={handleFormChange}
                      >
                        <option value="Ghana National Identification">
                          Ghana National Identification
                        </option>
                      </select>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="id_number">
                        ID Number<span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="IDCardNumber"
                        id="id_number"
                        onChange={handleFormChange}
                      />
                      <span className="error-message">
                        {formError.IDCardNumber}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>
                        Current contribution (Amount in Ghana Cedis)
                        <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="current_contribution"
                        onChange={handleFormChange}
                      />
                      <span className="error-message">
                        {formError.current_contribution}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>
                        {fundType === "increment"
                          ? "Requested Increment Amount (GHC)"
                          : "Requested Decrement Amount (GHC)"}
                        <span className="bto-lb-required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="requested_increment"
                        onChange={handleFormChange}
                      />
                      <span className="error-message">
                        {formError.requested_increment}
                      </span>
                    </div>
                  </div>

                  <div className="submitButtonContainer">
                    <button
                      className="button"
                      style={{ background: "var(--primaryColor" }}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit {fundType} Application
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>

          <div className="incrementDisplay">
            <h2>Fund Increased</h2>
            {fund_increment.length > 0 && (
              <>
                {fund_increment?.map((fund, index) => (
                  <div key={index} className="statusContainer cursor-pointer">
                    <div className="flex justify-between items-start">
                      <div className="flex">
                        <Avatar
                          src={auth?.user?.profile_image}
                          className="icon mr-3"
                        />
                        <div>
                          <h5 className="notif_title pb-1">
                            Requested Amount:{" "}
                            <span className="text-bold">
                              {fund?.requested_increment}
                            </span>
                          </h5>
                          <h5 className="notif_title pb-1">
                            Current contribution:{" "}
                            <span>{fund?.current_contribution}</span>
                          </h5>
                          <div className="notif_msg pb-1">
                            Start date: {moment(fund?.created_at).format("MMM Do YY")}
                            
                          </div>
                        </div>
                      </div>
                      <div className="text-right ml-4">
                        <div className="text-sm text-gray-600">
                          <strong>NB: </strong> Please this will take effect
                          after 3months
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            <hr />
            <h2 className="py-2 ">Fund Decreased</h2>
            {fund_decrement.length > 0 && (
              <>
                {fund_decrement?.map((fund, index) => (
                  <div key={index} className="statusContainer cursor-pointer">
                    <div className="flex justify-between items-start">
                      <div className="flex">
                        <Avatar
                          src={auth?.user?.profile_image}
                          className="icon mr-3"
                        />
                        <div>
                          <h5 className="notif_title pb-1">
                            Requested Amount:{" "}
                            <span className="text-bold">
                              {fund?.requested_increment}
                            </span>
                          </h5>
                          <h5 className="notif_title pb-1">
                            Current contribution:{" "}
                            <span>{fund?.current_contribution}</span>
                          </h5>
                          <div className="notif_msg pb-1">
                            Start date: {moment(fund?.created_at).format("MMM Do YY")}
                          </div>
                        </div>
                      </div>
                      <div className="text-right ml-4">
                        <div className="text-sm text-gray-600">
                          <strong>NB: </strong> Please this will take effect
                          after 3months
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <ToastContainer autoClose={3000} />
    </RenderHome>
  );
}

export default FundAdjustment;

/* eslint-disable  */
import {
  Close,
  DashboardOutlined,
  FavoriteBorderOutlined,
  LiveHelp,
  Lock,
  Message,
  MoneyOutlined,
  Person,
  Receipt,
  Settings,
  SettingsApplicationsOutlined,
} from "@material-ui/icons";

import React, { useEffect, useState } from "react";
import { BsBasketFill, BsCalculator } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import {
  GiPayMoney,
  GiReceiveMoney,
  GiShoppingCart,
  GiTakeMyMoney,
} from "react-icons/gi";
import { GoListUnordered } from "react-icons/go";
import { SiGooglemessages } from "react-icons/si";
import { TiShoppingCart } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import "../../../../css/Dashboard/home/Home.css";
import { navigationActionCreators } from "../../../../service/action-creators";
import { FaGlobe, FaSlack, FaWpforms } from "react-icons/fa";

function SideBarMenu() {
  const dispatch = useDispatch();
  const navigation = useSelector((state) => state.navigation.openSideNav);
  const messages = useSelector((state) => state?.messages);
  const display = navigation ? "block" : "none";
  const device = () => {
    return window.innerWidth < 1080;
  };
  const [isMobile, setIsMobile] = useState(device);
  const { closeSideBarOnMobile } = bindActionCreators(
    navigationActionCreators,
    dispatch
  );
  console.log("navigation", navigation);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  return (
    <div
      className="SideBarMenu"
      style={{
        display: `${isMobile ? display : ""}`,
        background:
          "url(/images/dev/background.jpg) no-repeat center center/cover",
      }}
    >
      <div className="overlay">
        <div className="title_nd_closeBtn">
          <div className="mb4 pt2">
            {/* <img
              src={"/images/dev/logo.png"}
              style={{
                width: "100px",
              }}
            /> */}
          </div>
          <div className="hide_on_large">
            <span
              onClick={() => {
                closeSideBarOnMobile();
              }}
            >
              <Close
                className="mx-3 my-2"
                style={{ marginTop: "-20px", color: "white" }}
              />
            </span>
          </div>
        </div>
        <ul>
          <Link to="/home">
            <div className="icon">
              <DashboardOutlined />
            </div>
            <div>Dashboard</div>
          </Link>

          <Link to={"/loans"}>
            <div className="icon">
              <GiTakeMyMoney size={22} />
            </div>

            <div>Loans Deduction</div>
          </Link>
          <Link to={"/mart-deduction"}>
            <div className="icon">
              <MoneyOutlined size={22} />
            </div>
            <div>Mart Deduction</div>
          </Link>

          <Link to={"/fund"}>
            <div className="icon">
              <GiPayMoney size={22} />
            </div>
            <div>My Contributions</div>
          </Link>
      
          <Link to={"/fund-increment"}>
            <div className="icon">
            <GiPayMoney size={22} />
            </div>
            <div>Increase / Decrease Fund</div>
          </Link>
          <Link to={"/apply-loan-personal_info"}>
            <div className="icon">
              <GiReceiveMoney size={22} />
            </div>
            <div>Apply Loan</div>
          </Link>
          <Link to={"/my-applied-loans"}>
            <div className="icon">
              <GoListUnordered className="" size={20} />
            </div>
            <div>My applied Loans</div>
          </Link>
          <Link to={"/profile"}>
            <div className="icon">
              <CgProfile size={22} />
            </div>
            <div>My Profile</div>
          </Link>

          <Link to={"/upnmg-shopping-mall"}>
            <div className="icon">
              {/* <ShoppingBasketOutlined /> */}
              <TiShoppingCart size={23} />
            </div>
            <div>UPNMG Mart</div>
          </Link>
          <Link to={"/my-orders-and-purchase"}>
            <div className="icon">
              <FaWpforms className="" size={20} />
            </div>
            <div>My Orders </div>
          </Link>
          <Link to={"/favourites"}>
            <div className="icon">
              <FavoriteBorderOutlined />
            </div>
            <div>My Favourites</div>
          </Link>
          <Link to={"/my-cart"}>
            <div className="icon">
              <GiShoppingCart size={23} />
              {/* <ShoppingBasketOutlined /> */}
            </div>
            <div>My Cart</div>
          </Link>
         
         {/* TODO:: WE HAVE HIDE THE REQUESTED FOR FUTURE UPDATES */}
          {/* <Link to={"/request-order"}>
            <div className="icon">
              <FaSlack className="" size={20} />
            </div>
            <div>Request Order </div>
          </Link> */}
         
          <Link to={"/message"}>
            <div className="icon">
              <SiGooglemessages className="" size={20} />
            </div>
            <div>Messenger ({`${messages?.unreadMessagesTotal}`})</div>
          </Link>
          <Link to={"/calculator"}>
            <div className="icon">
              <BsCalculator className="" size={20} />
            </div>
            <div>Loan Calculator</div>
          </Link>
          <hr />

          <Link to={"/settings"}>
            <div className="icon">
              <Settings />
            </div>
            <div>Settings</div>
          </Link>
          <Link to={"/faq"}>
            <div className="icon">
              <LiveHelp />
            </div>
            <div>
              FAQ<small>s</small>
            </div>
          </Link>
          <a href="https://upnmg.com" target="_blank">
            <div className="icon">
              <FaGlobe />
            </div>
            <div>UPNMG WEBSITE</div>
          </a>

         

          <Link to={"/logout"}>
            <div className="icon">
              <Lock />
            </div>
            <div>Logout</div>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default SideBarMenu;

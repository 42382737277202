/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import "../../../css/Pages/Pages.css";
import { authActionCreators } from "../../../service/action-creators";
import Loader from "../../Loader";
import Resizer from "react-image-file-resizer";
function ChangePasswordAndEmail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [formData, setFormData] = useState({});
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [resizedProfileImage, setResizedProfileImage] = useState(null);

  const { ChangePasswordAndEmail, ResetResponse, GetUserProfile } =
    bindActionCreators(authActionCreators, dispatch);
  const user = useSelector((state) => state.auth.user);
  const response = useSelector((state) => state.auth.response);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const handlechange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  console.log("user", user);
  useEffect(() => {
    GetUserProfile();
  }, []);
  useEffect(() => {
    if (user?.profile_image) {
      setResizedProfileImage(user?.profile_image);
    }
  }, [user]);
  useEffect(() => {
    if (user?.changed_password && user?.is_account_active) {
      history.push("/home");
    }
  }, [user?.changed_password, user?.is_account_active]);

  console.log("user", user);
  console.log("resizedProfileImage", resizedProfileImage);
  const handleProfileChange = (e) => {
    if (e?.target.files[0]) {
      const file = e?.target.files[0];
      const reader = new FileReader();

      reader.onload = (ev) => {
        setProfileImagePreview(ev?.target?.result);
        console.log("prof", ev?.target?.result);
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            100,
            100,
            "png",
            100,
            0,
            (uri) => {
              console.log(uri);
              setResizedProfileImage(uri);
            },
            "file",
            200,
            200
          );
        } catch (err) {
          console.log(err);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  // const handleProfileChange = () => {

  // }
  console.log("profileImagePreview", profileImagePreview);

  const validateEmail = (email) => {
    // Regular expression for a valid email address
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // Use the test method to check if the email matches the regular expression
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.password ||
      !formData.confirmPassword ||
      !formData.email ||
      !formData.phone_number
    ) {
      toast.error("All fields are required");
      return;
    } else if (formData.password !== formData.confirmPassword) {
      toast.error("Your password do not match");
    } else if (!resizedProfileImage) {
      toast.error("Please upload passport picture");
    } else if (!formData.dob) {
      toast.error("Date of birth is required");
    } else if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    } else if (formData.email !== formData.confirm_email) {
      toast.error("Please your email do not match");
      return;
    } else {
      ChangePasswordAndEmail(formData, resizedProfileImage);
    }
  };
  useEffect(() => {
    if (user?.changed_password && user?.email) {
      return <Redirect to={"/home"} />;
    }
  }, [user]);

  useEffect(() => {
    if (response.state === "SUCCESS") {
      toast.success(response?.message ?? "Please verify your email");
      history.push("/account/email-sent");
      // return <Redirect to={'/account/email-sent'}/>
      setTimeout(() => {
        ResetResponse();
        // history.replace('/home')
      }, 1500);
    } else if (response.state === "ERROR") {
      toast.error(response?.message ?? "unable to update user");
      setTimeout(() => {
        ResetResponse();
      }, 1500);
    }
    //  else if (response.state === "UPLOADED") {
    //   toast.success(response?.message ?? "Passport uploaded successfully");
    //   setTimeout(() => {
    //     ResetResponse();
    //     window.location.reload(true)
    //   }, 1500);
    // }
  }, [response.state]);

  // console.log('user?.changed_password', user?.changed_password)
  return (
    <div className="ConfirmEmailPassword">
      <div className="overlay">
        {isLoading && <Loader />}
        <form autocomplete="off">
          <h3 className="title text-2xl  font-bolder">
            {user?.name || "Unknown..."}
          </h3>
          <h6 className="title text-lg  font-bold">Update Your Profile</h6>

          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-0 pb-0">
                <svg
                  aria-hidden="true"
                  className="w-7 h-6 mb-1 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <label className="font-small text-xs">
                    Click to upload passport image
                  </label>
                </p>
                {/* <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
              </div>
              <input
                id="dropzone-file"
                onChange={handleProfileChange}
                type="file"
                className="hidden"
              />
            </label>
            <div
              style={{
                width: "150px",
                height: "100px",
                overflow: "hidden",
                // background: `url(${
                //   profileImagePreview
                //     ? profileImagePreview
                //     : user?.profile_image
                // }) no-repeat center center/cover`,
              }}
              className="ml-2  border-2 border-gray-300 border-dotted "
            >
              <img
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                src={
                  profileImagePreview
                    ? profileImagePreview.toString()
                    : user?.profile_image
                }
              />
              {/* <img src="/images/dev/default-profile-image.png"/> */}
            </div>
          </div>

          <div className="row">
            <div className="formInput col-md-6">
              <label>Email Address</label>
              <input
                type={"email"}
                name="email"
                required
                autoComplete="false"
                placeholder="example@gmail.com"
                className="form-control"
                onChange={handlechange}
              />
            </div>
            <div className="formInput col-md-6">
              <label>Confirm Email Address</label>
              <input
                type={"email"}
                name="confirm_email"
                required
                autoComplete="false"
                placeholder=""
                className="form-control"
                onChange={handlechange}
              />
            </div>
          </div>
          <div className="row">
            <div className="formInput col-md-6">
              <label>Phone Number</label>
              <input
                type={"text"}
                name="phone_number"
                required
                autoComplete="false"
                placeholder=""
                className="form-control"
                onChange={handlechange}
              />
            </div>
            <div className="formInput col-md-6">
              <label>Date of Birth</label>
              <input
                type={"date"}
                name="dob"
                required
                autoComplete="false"
                placeholder="Date of Birth"
                className="form-control"
                onChange={handlechange}
              />
            </div>
          </div>

          <div className="row">
            <div className="formInput col-md-6">
              <label>New Password</label>
              <div className="inputGroup">
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  placeholder=""
                  className="form-control"
                  onChange={handlechange}
                />
                <span
                  className="icon"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            <div className="formInput col-md-6">
              <label>Confirm Password</label>
              <div className="inputGroup">
                <input
                  type={`${showPasswordConfirm ? "text" : "password"}`}
                  name="confirmPassword"
                  placeholder=""
                  className="form-control"
                  onChange={handlechange}
                />
                <span
                  className="icon"
                  onClick={() => {
                    setShowPasswordConfirm(!showPasswordConfirm);
                  }}
                >
                  {showPasswordConfirm ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
          </div>

          <div
            className=""
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button className="button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </form>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default ChangePasswordAndEmail;

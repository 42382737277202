import { userDataConstants } from "../constants/userDataConstants";

const initialState = {
  isLoading: false,
  funds: [],
  fund_increment: [],
  fund_decrement: [],
  fundsOpeningBalance: null,
  dues: [],
  loans: [],
  mark_deductions: [],
  loanTemp: {},
  myAppliedLoans: [],
  pin_renewal: [],
  loan: {},
  totalFunds: 0,
  paginate: {
    total: null,
    totalItems: null,
    page: null,
    size: null,
    previousPage: null,
    currentPageSize: null,
    nextPage: null,
    previousNumber: null,
    nextNumber: null,
  },
  response: {
    state: null,
    message: "",
    action: "",
  },
  favoriteProducts: [],
  cart: [],
  orders: [],
  order: {},
  temp_order: {},
  cartGrandTotal: 0,
  prodQuantity: 1,
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case userDataConstants.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case userDataConstants.GET_FUNDS:
      return {
        ...state,
        funds: action.payLoad.funds,
        paginate: action.payLoad.paginate,
      };
    case userDataConstants.GET_MY_FUNDS_INCREMENT:
      return {
        ...state,
        fund_increment: action.payLoad.fund_increment,
      };
    case userDataConstants.GET_MY_FUNDS_DECREMENT:
      return {
        ...state,
        fund_decrement: action.payLoad.fund_decrement,
      };
    case userDataConstants.GET_FUNDS_OPENING_BALANCE:
      return {
        ...state,
        fundsOpeningBalance: action.payLoad.fundsOpeningBalance,
      };
    case userDataConstants.GET_TOTAL_FUNDS:
      return {
        ...state,
        totalFunds: action.payLoad,
      };
    case userDataConstants.GET_PIN_RENEWAL:
      return {
        ...state,
        pin_renewal: action.payload
      };
    case userDataConstants.GET_DUES:
      return {
        ...state,
        dues: action.payLoad.dues,
        paginate: action?.payLoad?.paginate,
      };
    case userDataConstants.GET_LOANS:
      return {
        ...state,
        loans: action.payLoad.loans,
        paginate: action?.payLoad?.paginate,
      };
    case userDataConstants.GET_MART_DEDUCTION:
      return {
        ...state,
        mark_deductions: action.payLoad.mark_deductions,
        paginate: action?.payLoad?.paginate,
      };
    case userDataConstants.GET_FAVORITE_PRODUCTS:
      return {
        ...state,
        favoriteProducts: action.payLoad.favoriteProducts,
      };
    case userDataConstants.GET_CART_PRODUCTS:
      return {
        ...state,
        cart: action.payLoad.cart,
        cartGrandTotal: action.payLoad.cartGrandTotal,
      };
    case userDataConstants.GET_ORDERS:
      return {
        ...state,
        orders: action.payLoad.orders,
      };
    case userDataConstants.RESPONSE_STATE:
      return {
        ...state,
        response: {
          state: action?.response?.state,
          message: action?.response?.message,
        },
      };
    case userDataConstants.GET_APPLIED_LOANS:
      return {
        ...state,
        myAppliedLoans: action?.payLoad?.myAppliedLoans,
      };
    case userDataConstants.GET_APPLIED_LOAN_DETAILED:
      return {
        ...state,
        loan: action?.payLoad?.loan,
      };
    case userDataConstants.GET_APPLIED_ORDER_DETAILED:
      return {
        ...state,
        order: action?.payLoad?.order,
      };
    case userDataConstants.GET_TEMPORARY_ORDER:
      return {
        ...state,
        temp_order: action?.payLoad?.temp_order,
      };
    case userDataConstants.GET_LOANS_FROM_TEMP:
      return {
        ...state,
        loanTemp: action?.payLoad?.loanTemp,
      };

    default:
      return state;
  }
};

export default userDataReducer;

/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import RenderHome from '../home/RenderHome';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userDataActionCreators } from '../../../service/action-creators';
import { toast } from 'react-toastify';
import Loader from '../../Loader';

function PinRenewal() {
    
    const dispatch = useDispatch()
    const { GetPinRenewal, AddPinRenewal, ResetUserDataResponse} = bindActionCreators(userDataActionCreators, dispatch)
    const {isLoading, pin_renewal, response} = useSelector(
      (state) => state?.user
    );

  const [formData, setFormData] = useState({
    name: '',
    facility: '',
    registrationNumber: '',
    pin: '',
    phoneNumber: ''
  });


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    if(formData.facility != '' || formData.registrationNumber != '' || formData.pin != '' || formData.phoneNumber != '' || formData.name != ''){
        AddPinRenewal({
            name: formData.name,
            pin: formData.pin,
            facility: formData.facility,
            registration_number: formData.registrationNumber,
            phone_number: formData.phoneNumber
        })
    }
 
  };

  useEffect(() => {
    GetPinRenewal()
  },[])

  useEffect(() => {
    if (response.state === "SUCCESS") {
      GetPinRenewal()
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response.state === "ERROR") {
      toast.error(response?.message ?? "unable to get requested orders");
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response.state]);

  return (
    <div className="PinRenewal">
      <RenderHome title={''}>
      {isLoading ? <Loader /> : ""}
        <div className="ticket mt-10 h-100">
          <h3 className="text-2xl font-semibold mb-6 text-center">Renew Your Pin</h3>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">

    
            <div className="col-span-1">
              <label>
                Name (As appears on payslip)<span className="bto-lb-required">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-none focus:outline-none focus:border-blue-500"
                required
              />
            </div>

         
            <div className="col-span-1">
              <label>
                Facility<span className="bto-lb-required">*</span>
              </label>
              <input
                type="text"
                name="facility"
                value={formData.facility}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-none focus:outline-none focus:border-blue-500"
                required
              />
            </div>

         
            <div className="col-span-1">
              <label>
               Registration<span className="bto-lb-required">*</span>
              </label>
              <input
                type="text"
                name="registrationNumber"
                value={formData.registrationNumber}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-none focus:outline-none focus:border-blue-500"
                required
              />
            </div>

       
            <div className="col-span-1">
            <label>
               Pin<span className="bto-lb-required">*</span>
              </label>
              <input
                type="text"
                name="pin"
                value={formData.pin}
                onChange={handleChange}
                className="w-full px-4 py-2 border bg-white border-gray-300 rounded-none focus:outline-none focus:border-blue-500"
                required
              />
            </div>

            
            <div className="col-span-1 md:col-span-2">
            <label>
               Phone Number<span className="bto-lb-required">*</span>
              </label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-none focus:outline-none focus:border-blue-500"
                required
              />
            </div>

            {/* Submit btn */}

          </form>
          <div className="col-span-1 md:col-span-2 mt-10 flex items-center justify-center">
            <button
              className="button"
              style={{ background: 'var(--primaryColor' }}
              name=""
              type="submit"

            >
              Submit Application
            </button>
          </div>
        </div>
      </RenderHome>
    </div>
  );
}

export default PinRenewal;

/* eslint-disable  */
import {
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { BsPrinter } from 'react-icons/bs';
import { FiDownloadCloud } from 'react-icons/fi';
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { bindActionCreators } from "redux";
// import "../../../css/Dashboard/home/Home.css";
import { userDataActionCreators } from "../../../service/action-creators";
import { GetUserProfile } from "../../../service/action-creators/authAction";
import { GetNotifications } from "../../../service/action-creators/notificationAction";
import { GetCartProducts } from "../../../service/action-creators/userDataAction";
import Loader from "../../Loader";
import RenderHome from "../home/RenderHome";
import './Loans.css';

function MartDeduction() {
  const dispatch = useDispatch();
  const {  GetMartDeduction } = bindActionCreators(userDataActionCreators, dispatch);
  //   const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const mark_deductions = useSelector((state) => state.user.mark_deductions);
  const paginate = useSelector((state) => state.user.paginate);
  const isLoading = useSelector((state) => state.user.isLoading);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(0);
  // const data = useSelector((state) => state.data);
 
  const componentRef = useRef();

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(mark_deductions?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, mark_deductions]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("event", event);

    GetMartDeduction(true, event.selected + 1, 15);

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };

  console.log("mark_deductions", mark_deductions);
  console.log("paginate", paginate);
  useEffect(() => {
    GetMartDeduction(true, 1, 15);

    dispatch(GetUserProfile());
    dispatch(GetNotifications());
    dispatch(GetCartProducts());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //   const handleClose = () => {
  //     // setOpen(false);
  //   };
  const handleToggle = () => {
    // setOpen(!open);
  };

  

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Funds Data',

  });

  const handleSearch = debounce((e) => {
    if(e.target.value){
     
        GetMartDeduction(true, 1, 10, `${e.target.value}`);
    }else{
        GetMartDeduction(true, 1, 10);

    }
  },800)

 
  const device = () => {
    return window.innerWidth < 1080;
  };

  const [isMobile, setIsMobile] = useState(device);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(device);
    });
    return 0;
  }, []);

  

  return (
    <>
      <div className="LoansPage">
        <RenderHome title={"Mart Deduction"}>
          {isLoading ? <Loader /> : ""}
          <div className="searchAndGridList">
            <div className="searchCover">
              <input
                type={"search"}
                name="searchProduct"
                placeholder="search by period eg. JAN22"
                onChange={handleSearch}
              />
            </div>
            <div className="listNdGridIcons">

              {/* <BsPrinter className="icon" onClick={handlePrint}/> */}
              <Link to="/upnmg-shopping-mall" className="button">Purchase Product</Link>
              {/* <FiDownloadCloud className="icon"/> */}
          
            </div>
          </div>

          <TableContainer component={Paper} className="fundsTableContainer" ref={componentRef}>
            <Table
              className="table"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="header">{isMobile ? 'Type' : 'Transaction Type'} </TableCell>
                  <TableCell className="header" align="right">Deduction</TableCell>
                  <TableCell className="header" align="right">Period</TableCell>
                  <TableCell className="header" align="right">Balance</TableCell>
                  <TableCell className="header" align="right">{isMobile ? 'Amount': 'Original Amount'} </TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody>
                {mark_deductions?.map((loan, index) => {
                  console.log(loan);
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="data" component="th" scope="row">
                        {loan?.transaction_type}
                      </TableCell>
                      <TableCell className="data"  align="right">{loan?.deduction}</TableCell>
                      <TableCell className="data"  align="right">{loan?.period}</TableCell>
                      <TableCell className="data"  align="right">{loan?.balance}</TableCell>
                      <TableCell className="data"  align="right">
                        {loan?.original_amount}
                      </TableCell>
                     
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <table className="min-w-full leading-normal">
          <thead>
            <tr>
           
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Amount 
             
              </th>
              <th
                className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Period
              </th>
              <th
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Transaction 
             
              </th>
              <th
                className="px-1 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Facility
              </th>
             
            </tr>
          </thead>
          <tbody>
            {funds?.length > 0 ? (<>
            {funds?.map((fund, index) => {
              return ( <tr>
               
                <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{'fund?.deduction'}</p>
                  
                </td>
                <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{'fund?.period'}</p>
                  
                </td>
                <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                
                   <p className="text-gray-900 whitespace-no-wrap">{'fund?.transaction_type'}</p>
                </td>
                <td className="px-1 py-1 border-b border-gray-200 bg-white text-sm">
                  
                   <p className="text-gray-900 whitespace-no-wrap">{'fund?.user?.facility'}</p>
                </td>
         
              </tr>)
            })}
            </>) : (<>Data is not available</>)}
           

          </tbody>
        </table> */}

        
      {mark_deductions.length > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                />
              )}

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleClose}>Download PDF</MenuItem>
            <MenuItem onClick={handleClose}>Download Excel</MenuItem>
          </Menu>
        </RenderHome>
      </div>
    </>
  );
}

export default MartDeduction;

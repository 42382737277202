/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userDataActionCreators } from "../../../service/action-creators";
import Loader from "../../Loader";
import RenderHome from "../home/RenderHome";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { SearchProducts } from "../../../service/action-creators/ProductAction";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

function EditMartForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { GetAppliedOrderDetailed, ResetUserDataResponse, UpdateOrderTempIDCardImageUpload, UpdateOrderTempPassportImageUpload, EditMartApplicationFormByMember } = bindActionCreators(
    userDataActionCreators,
    dispatch
  );
  const [formData, setFormData] = useState({});
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [formError, setFormError] = useState({});
  const [seletedFiles, setSeletedFiles] = useState([]);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [previewPassportImage, setPreviewPassportImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [productQuantity, setProductQuantity] = useState(1);
  const [period, setPeriod] = useState(3);


  

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const order_id = queryParams.get('order_id');

  const editFormChecked = history?.location?.state?.editFormChecked;
  const editFormAndProductChecked = history?.location?.state?.editFormAndProductChecked;

  const userData = useSelector((state) => state?.user);
  const searchedProducts = useSelector((state) => state?.product?.products);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;

  const { response, isLoading, order } = userData;
  let timestamp = new Date().getTime();

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
  };

  const handleGhanaCardUpload = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewFrontCard(ev?.target?.result);

        try {
          Resizer.imageFileResizer(
            file,
            400,
            400,
            "png",
            100,
            0,
            (uri) => {
              console.log(uri);
              UpdateOrderTempIDCardImageUpload(
                uri, // file, 
                order?.frontIDImage_key ? order?.frontIDImage_key : "",
                order?._id,
                "main"
              );
            },
            "file",

          );

        } catch (err) {
          console.log(err);
        }


      };
      setSeletedFiles([...seletedFiles, file]);
      reader.readAsDataURL(file);
    }
  };
  const handlePassportChange = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewPassportImage(ev?.target?.result);
        console.log("file", file);
        try {
          Resizer.imageFileResizer(
            file,
            400,
            400,
            "png",
            100,
            0,
            (uri) => {
              UpdateOrderTempPassportImageUpload(
                uri,
                order?.passportImage_key ? order?.passportImage_key : "",
                order?._id,
                "main"
              );
            },
            "file",
   
          );

        } catch (err) {
          console.log(err);
        }

      };

      reader.readAsDataURL(file);
    }
  };

  const handleFormEditSubmit = (e) => {
    e.preventDefault();
    // alert('12')
    console.log("formData", formData);
    console.log('cartItems', cartItems)
      EditMartApplicationFormByMember({ ...formData, order: cartItems }, order?._id);
    // if (cartItems.length > 0) {
    //   console.log('formData not empty', {...formData, order: cartItems})
    //   // EditMartApplicationFormByMember({ ...formData, order: cartItems }, order?._id);
    // } else {
    //   console.log('formData emty', formData)
    //   // EditMartApplicationFormByMember(formData, order?._id);
    // }
  };



  console.log('order', order)

  useEffect(() => {
    GetAppliedOrderDetailed(order_id);
  }, [order_id]);

  useEffect(() => {
    setCartItems(order?.order || [])
  }, [order]);



  useEffect(() => {
    if (response?.state === "SUCCESS") {
      GetAppliedOrderDetailed(order_id);
      history.push("/my-orders-and-purchase")
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      GetAppliedOrderDetailed(order_id);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    } else if (response?.state === "UPLOAD_SUCCESS") {
      GetAppliedOrderDetailed(order_id);
      toast.success(response?.message);
      window.location.reload(false);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message]);





  const handleSearchChange = debounce((e) => {
    console.log('e.target.value', e.target.value);
    e.preventDefault();
    if (e.target.value) {
      dispatch(SearchProducts(e.target.value, true, 1, 5));
    } else {
      dispatch(SearchProducts(null, true, 1, 5));
    }
  }, 800);

  // const handleSearchChange = (e) => {
  //   console.log('e.target.value', e.target.value);  
  // }


  const handleAddToCart = (product) => {

    console.log('product', product)

    // alert(carts[0]?.period)
    let selectedDuration;
    let percentChange;

    if (Number(period) === 24) {
      percentChange = 1.2
      selectedDuration = Number(period);
    } else if (Number(period) === 18) {
      percentChange = 1.145
      selectedDuration = Number(period);
    } else if (
      // Number(carts[0].period) == 1 ||
      Number(period) === 3 ||
      Number(period) === 6 ||
      Number(period) === 12
    ) {
      percentChange = 1.1
      selectedDuration = Number(period); // 10%
    }

    const adminCharge = 3 / 100;
    const retailPrice = Number(product?.new_price)
    const adminAmount = retailPrice * adminCharge;
    const initialAmount = (retailPrice * percentChange) + adminAmount;

    let instalPerMonth = initialAmount / selectedDuration


    console.log('retailPrice', retailPrice)
    console.log('initialAmount', initialAmount)


    setCartItems([
      ...cartItems,
      {
      product_id: product?._id,
      product_name: product?.product_name,
      product_price: product.new_price,
      product_old: product?.old_price,
      product_quantity: product?.quantity,
      product_img: [{ url: product?.images[0]?.url }],
      qty: productQuantity,
      period: period,
      period_price: instalPerMonth,
      color: '',
      size: '',
      user_id: user?._id,
      sub_total: (instalPerMonth * productQuantity),
      }
    ]);
  };

  // editFormAndProductChecked
  // editFormWithoutProductChecked

  return (
    <div className="EditMartForm">
      <RenderHome title={"Edit Loan Application Form"}>
        {isLoading ?? <Loader />}
        <form action="">
        {/* {(order?.rejected[0]?.editFormChecked == true || order?.rejected[0]?.editFormAndProductChecked == true) && (
          <> */}
          
          <div className="form_area bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto">
            <h3 className="text-xl font-semibold mb-4">Personal Information</h3>

            {/* Full Name */}
            <div className="mb-4">
              <label htmlFor="fullname" className="block text-sm font-medium text-gray-700">
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="fullname"
                name="fullname"
                readOnly
                defaultValue={order?.name}
                className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            {/* Date of Birth, Age, and SSNIT */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
              <div>
                <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">
                  Date of Birth <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  required
                  defaultValue={order?.dateOfBirth}
                  onChange={(e) => handleDoBChange(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="age" className="block text-sm font-medium text-gray-700">Age</label>
                <input
                  type="text"
                  id="age"
                  name="age"
                  readOnly
                  defaultValue={order?.age}
                  className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="ssnit" className="block text-sm font-medium text-gray-700">
                  SSNIT <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="ssnit"
                  name="ssnit"
                  required
                  defaultValue={order?.ssnit}
                  onChange={(e) => handleFormChange(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            {/* ID Type, ID Number, and Gender */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
              <div>
                <label htmlFor="IdCardType" className="block text-sm font-medium text-gray-700">
                  ID Type <span className="text-red-500">*</span>
                </label>
                <select
                  id="IdCardType"
                  name="IdCardType"
                  defaultValue="Ghana National Identification"
                  onChange={(e) => handleFormChange(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value="Ghana National Identification">Ghana National Identification</option>
                </select>
              </div>
              <div>
                <label htmlFor="id_number" className="block text-sm font-medium text-gray-700">
                  ID Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="id_number"
                  name="IDCardNumber"
                  defaultValue={order?.IDCardNumber}
                  onChange={(e) => handleFormChange(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                  Gender <span className="text-red-500">*</span>
                </label>
                <select
                  id="gender"
                  name="gender"
                  defaultValue={order?.gender}
                  onChange={(e) => handleFormChange(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            {/* Residential and Work Address */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label htmlFor="residential_address1" className="block text-sm font-medium text-gray-700">
                  Residential Address <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="residential_address1"
                  name="residential_address1"
                  defaultValue={order?.residential_address1}
                  onChange={(e) => handleFormChange(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                <input
                  type="text"
                  id="residential_address2"
                  name="residential_address2"
                  defaultValue={order?.residential_address2}
                  onChange={(e) => handleFormChange(e)}
                  className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="work_address1" className="block text-sm font-medium text-gray-700">
                  Work Address <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="work_address1"
                  name="work_address1"
                  defaultValue={order?.work_address1}
                  onChange={(e) => handleFormChange(e)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                <input
                  type="text"
                  id="work_address2"
                  name="work_address2"
                  defaultValue={order?.work_address2}
                  onChange={(e) => handleFormChange(e)}
                  className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>


          <>
            {/* THE CONTACT DETAILS SECTION */}
            <div className="form_area bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto mt-3">
              <h3 className="text-xl font-semibold mb-4">Contact Details</h3>

              <div className="form-group pb-3 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700">Phone Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="personal_phone"
                    defaultValue={order?.personal_phone}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700">Work Phone<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    defaultValue={order?.work_phone}
                    name="work_phone"
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>

              <div className="form-group pb-3">
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700">Email Address<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="email"
                    name="email"
                    defaultValue={order?.email}
                    placeholder="example@gmail.com"
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>

              <div className="form-group pb-3 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700">Mandate Number 1<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="mandate_number"
                    defaultValue={order?.mandate_number}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700">OTP Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="otp_number"
                    defaultValue={order?.otp_number}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>

              <div className="form-group pb-3 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700">Mandate Number 2<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-smEmployer"
                    type="text"
                    name="mandate_number"
                    defaultValue={order?.mandate_number2}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700">OTP Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="otp_number2"
                    defaultValue={order?.otp_number2}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>
            </div>
          </>

          {/* Guarantors one  */}
          <div className="form_area bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto mt-3">
            <div className="form_area form2">
              <div className="form-group row">
                <h3 className="text-xl font-semibold mb-4">Guarantors 1</h3>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Name<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_name"
                    defaultValue={
                      order?.guarantor_name
                      // ||
                      // (loanTemp?.guarantor_name ?? loanTemp?.guarantor_name)
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Staff ID<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_staff_id"
                    defaultValue={
                      order?.guarantor_staff_id
                      // ||
                      // (loanTemp?.guarantor_staff_id ??
                      //   loanTemp?.guarantor_staff_id)
                    }
                    lettersonly=""
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Phone Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_phone"
                    placeholder=""
                    defaultValue={
                      order?.guarantor_phone
                     
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Ghana Card Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_ghana_card"
                    defaultValue={
                      order?.guarantor_ghana_card
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">GPS Address<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_gps"
                    required
                    defaultValue={
                      order?.guarantor_gps
                     
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Occupation<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_occupation"
                    defaultValue={
                      order?.guarantor_occupation
                   
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Mandate Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_mandate_number"
                    defaultValue={
                      order?.guarantor_mandate_number
                 
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">OTP Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_otp_number"
                    defaultValue={
                      order?.guarantor_otp_number
                  
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
            
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Relationship<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_relationship"
                    defaultValue={
                      order?.guarantor_relationship
                   
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
             
                </div>
              </div>
              {/* SECOND GUARANTOR SECTION */}
              <div className="form-group row">
                <h3 className="text-xl font-semibold mt-3">Guarantors 2</h3>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Name<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_name2"
                    defaultValue={
                      order?.guarantor_name2
                     
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
               
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Staff ID<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_staff_id2"
                    defaultValue={
                      order?.guarantor_staff_id2
                      
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                 
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Phone Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_phone2"
                    placeholder=""
                    defaultValue={
                      order?.guarantor_phone2
                      
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                 
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Ghana Card Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_ghana_card2"
                    defaultValue={
                      order?.guarantor_ghana_card2
                      
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                 
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">GPS Address<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_gps2"
                    required
                    defaultValue={
                      order?.guarantor_gps2
                      
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Occupation<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_occupation2"
                    defaultValue={
                      order?.guarantor_occupation2
                      
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Mandate Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="guarantor_mandate_number2"
                    defaultValue={
                      order?.guarantor_mandate_number2
                     
                    }
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />

                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">OTP Number<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_otp_number2"
                    defaultValue={
                      order?.guarantor_otp_number2
                     
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>



                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Relationship<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="guarantor_relationship2"
                    defaultValue={
                      order?.guarantor_relationship2
                     
                    }
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>

            {/* THE CONTACT DETAILS SECTION */}
            <div className="form_area bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto mt-3">
              <div className="form-group">
                <h3 className="text-xl font-semibold mb-4">Employment Details</h3>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <label className="block text-sm font-medium text-gray-700">Employer<span className="text-red-500">*</span></label>

                        <select
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          type="text"
                          name="employer_name"
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        >
                          <option value={order?.employer_name}>
                            {order?.employer_name}
                          </option>
                          <option value={"MOH"}>MOH</option>
                          <option value={"GHS"}>GHS</option>
                          <option value={"CHAG"}>CHAG</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="block text-sm font-medium text-gray-700">Facility<span className="text-red-500">*</span></label>
                    <input
                      className="mt-1 block w-full px-3 py-2 border bg-gray-100 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      type="text"
                      name="facility"
                      required
                      readOnly
                      value={order?.facility}
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <label className="block text-sm font-medium text-gray-700">Staff ID<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="staff_id"
                    readOnly
                    value={order?.staff_id}
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <label className="block text-sm font-medium text-gray-700">Employment Start Date<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="date"
                    name="employment_start_date"
                    defaultValue={order?.employment_start_date}
                    required
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                 
                </div>
              </div>

              <div className="form-group row">
          
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Occupation<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="occupation"
                    defaultValue={order?.occupation}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Employer Location<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    required
                    name="employer_location"
                    defaultValue={order?.employer_location}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
            
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label className="block text-sm font-medium text-gray-700">Affordability<span className="text-red-500">*</span></label>
                  <input
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    type="text"
                    name="affordability"
                    required
                    defaultValue={order.affordability}
                    //  value={user?.facility}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
               
                </div>
              </div>


            </div>

          </div>

          {/* </>
        )} */}
                    {/* START PRODUCT INFORMATION SECTION */}
          <>

            <div className="mt-4">
              <h3 className="text-xl font-semibold mb-4">Product Info</h3>
                {/* {order?.rejected[0]?.editFormAndProductChecked == true && (
                  <> */}
                    {/* Search Input boxz */}
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search for products for new products and add..."
                onChange={handleSearchChange}
                className="w-full px-4 py-2 mb-4 border rounded-md shadow-sm focus:outline-none focus:border-blue-500"
              />
                  {/* </>
                )} */}
            

              {/* showing products only when the search query matches */}
              {searchedProducts?.length === 0 ? (
                <div className="text-center text-gray-500 mt-4">No products found</div>
              ) : (
                <div className="flex gap-6 flex-wrap mb-4">
                  {searchedProducts?.map((product, idx) => (
                    <div key={idx} className="flex items-center gap-2 flex-col w-64 p-4 border rounded-lg shadow-md bg-white">
                      <img
                        className="w-50 h-20 object-contain flex items-center"
                        src={product?.images[0]?.url}
                        alt="product-img"
                      />
                      <div className="text-center font-medium text-sm mb-1 min-h-[40px] line-clamp-2">
                        {product?.product_name}
                      </div>
                      <div className="text-center text-lg font-bold text-gray-700 mb-3">
                        {product?.new_price?.toFixed(2)}
                      </div>

                      <div className="flex flex-col gap-3 mt-auto">
                        <div className="flex justify-between items-center gap-2">
                          <select
                            value={period}
                            onChange={(e) => setPeriod(Number(e.target.value))}
                            className="flex-1 px-3 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm bg-white"
                          >
                            <option value={3}>3 months</option>
                            <option value={6}>6 months</option>
                            <option value={12}>12 months</option>
                            <option value={18}>18 months</option>
                            <option value={24}>24 months</option>
                          </select>
                          <input
                            type="number"
                            min="1"
                            value={productQuantity}
                            onChange={(e) => setProductQuantity(Number(e.target.value))}
                            className="w-20 px-3 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                          />
                        </div>
                      </div>

                      <button
                      // type="button"
                        onClick={(e) =>{
                          e.preventDefault();
                           handleAddToCart(product)}}
                        className="w-full bg-blue-500 text-white py-1 rounded-md shadow-sm hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        Add
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {/* Product Table */}
              <div className="overflow-x-auto mt-6">
                <table className="min-w-full text-sm text-left border border-gray-300">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="px-4 py-2 border">#</th>
                      <th className="px-4 py-2 border">Image</th>
                      <th className="px-4 py-2 border">Product Name</th>
                      <th className="px-4 py-2 border">Qty</th>
                      <th className="px-4 py-2 border">Terms (m)</th>
                      <th className="px-4 py-2 border">Instalment p/m</th>
                      <th className="px-4 py-2 border">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems?.map((item, index) => (
                      <tr key={index} className="border-t">
                        <td className="px-4 py-2 border">{index + 1}</td>
                        <td className="px-4 py-2 border">
                          <img className="w-12" src={item?.product_img[0]?.url} alt="product-image" />
                        </td>
                        <td className="px-4 py-2 border">{item?.product_name}</td>
                        <td className="px-4 py-2 border">{item?.qty}</td>
                        <td className="px-4 py-2 border">{item?.period}</td>
                        <td className="px-4 py-2 border">{item?.period_price.toFixed(2)}</td>
                        <td className="px-4 py-2 border">
                        {/* {order?.rejected[0]?.editFormAndProductChecked == true && ( */}
                          <button
                          type="button"
                            className="bg-red-400 text-white  rounded-md px-2 py-1 cursor-pointer"
                            style={{backgroundColor: "rgb(255, 116, 116)"}}
                            onClick={(e) => {
                              e.preventDefault();
                              setCartItems(cartItems.filter((_, i) => i !== index))
                            }}
                          >
                            Remove
                          </button>

                         {/* )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>



            <div className="form_area form4 p-4 sm:p-6 lg:p-8">
              <h3 className="text-xl font-semibold mb-4">Upload ID Card</h3>
              <div className="description text-sm sm:text-base mb-6">
                Only Ghana National Identification card is accepted. Other ID cards will be rejected.
              </div>
              <div className="flex flex-col sm:flex-row justify-between gap-6">
                {/* Front Card Upload */}
                <div className="card_container flex-1 bg-white p-4 border rounded-lg shadow-md">
                  <div className="image-card mb-4 w-full flex justify-center">
                    <img
                      className="w-32 h-32 sm:w-48 sm:h-48 object-cover rounded-lg"
                      src={
                        previewFrontCard ||
                        (order?.frontIDImage ?? order?.frontIDImage + '?' + timestamp)
                      }
                      alt="front-id"
                    />
                  </div>
                  <div className="labelAndInput">
                    <div className="label font-semibold text-sm sm:text-base">Upload front card</div>
                    <input
                      type="file"
                      name="frontCard"
                      onChange={(e) => handleGhanaCardUpload(e)}
                      className="mt-2  text-sm border rounded-md w-full max-w-xs"
                    />
                  </div>
                </div>

                {/* Back Card Upload */}
                <div className="card_container flex-1 bg-white p-4 border rounded-lg shadow-md">
                  <div className="image-card mb-4 w-full flex justify-center">
                    <img
                      className="w-32 h-32 sm:w-48 sm:h-48 object-cover rounded-lg"
                      src={
                        previewPassportImage ||
                        (order?.passportImage ?? order?.passportImage + '?' + timestamp)
                      }
                      alt="Passport Photo"
                    />
                  </div>
                  <div className="labelAndInput">
                    <div className="label font-semibold text-sm sm:text-base">Upload back card</div>
                    <input
                      type="file"
                      name="passport"
                      onChange={(e) => handlePassportChange(e)}
                      className="mt-2  text-sm border rounded-md w-full max-w-xs"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center mt-6 gap-4">
                <button
                  className="button_cancel backBtn px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 focus:outline-none"
                  onClick={() => history.push("/home")}
                >
                  Home
                </button>
                <button
                  className="button px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
                  onClick={(e) => {
                    handleFormEditSubmit(e);
                  }}
                >
                  Save and Re-submit
                </button>
              </div>
            </div>

          </>

        </form>
      </RenderHome>
    </div>
  );
}

export default EditMartForm;

export const userDataConstants = {
    GET_FUNDS_OPENING_BALANCE:"GET_FUNDS_OPENING_BALANCE",
    GET_FUNDS:"GET_FUNDS",
    GET_MY_FUNDS_INCREMENT:"GET_MY_FUNDS_INCREMENT",
    GET_MY_FUNDS_DECREMENT:"GET_MY_FUNDS_DECREMENT",
    GET_TOTAL_FUNDS:"GET_TOTAL_FUNDS",
    GET_LOANS:"GET_LOANS",
    GET_ORDERS:"GET_ORDERS",
    GET_TEMPORARY_ORDER:"GET_TEMPORARY_ORDER",
    GET_APPLIED_ORDER_DETAILED:"GET_APPLIED_ORDER_DETAILED",
    GET_DUES:"GET_DUES",
    GET_FAVORITE_PRODUCTS:"GET_FAVORITE_PRODUCTS",
    GET_CART_PRODUCTS:"GET_CART_PRODUCTS",
    LOADING:"LOADING",
    RESPONSE_STATE: "RESPONSE_STATE",
    SUBMIT_LOAN_FORM: "SUBMIT_LOAN_FORM",
    GET_APPLIED_LOANS:"GET_APPLIED_LOANS",
    GET_APPLIED_LOAN_DETAILED:"GET_APPLIED_LOAN_DETAILED",
    GET_LOANS_FROM_TEMP:"GET_LOANS_FROM_TEMP",
    GET_PIN_RENEWAL:"GET_PIN_RENEWAL",
    GET_MART_DEDUCTION:"GET_MART_DEDUCTION",
    
}
/* eslint-disable  */
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  authActionCreators,
  userDataActionCreators,
} from "../../../service/action-creators";
import Loader from "../../Loader";
import RenderHome from "../home/RenderHome";
import "./ApplyLoans.css";

function ApplyLoans() {
  const dispatch = useDispatch();
  const history = useHistory();
  const signatureRef = useRef({});
  let timestamp = new Date().getTime();
  
  const loan_id = history.location.state.loan_id;

  const {
    EditLoanApplicationFormByMember,
    UpdateIDCardUpload,
    GetAppliedLoanDetailed,
    UpdateLoanTempPassportImageUpload,
    ResetUserDataResponse,
  } = bindActionCreators(userDataActionCreators, dispatch);

  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const [formState, setFormState] = useState(1);
  const [previewFrontCard, setPreviewFrontCard] = useState(null);
  const [previewBackCard, setPreviewBackCard] = useState(null);
  const [previewPassportImage, setPreviewPassportImage] = useState(null);

  const [formData, setFormData] = useState({});
  const [loanAmount, setLoanAmount] = useState(0);
  const [affordability, seAffordability] = useState(0);
  const [loanTerm, setLoanTerm] = useState(null);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);
  const [age, setAge] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const userData = useSelector((state) => state?.user);
  // const isLoading = useSelector((state) => state?.user?.isLoading);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;

  const { response, isLoading, loan } = userData;

  console.log("formState", formState);
  console.log("loanTerm", loanTerm);
  console.log("loan", loan);
  console.log("user", user);

  const handlePassportChange = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewPassportImage(ev?.target?.result);
        UpdateLoanTempPassportImageUpload(
          file,
          loan?.passportImage_key ? loan?.passportImage_key : "",
          loan?._id,
          "main"
        );
      };

      reader.readAsDataURL(file);
    }
  };
  const handleIDCardChange = (e) => {
    e.preventDefault();
    if (e?.target?.files[0]) {
      console.log("name", e?.target?.name);
      const file = e?.target?.files[0];
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewFrontCard(ev?.target?.result);
        UpdateIDCardUpload(
          file,
          loan?.frontIDImage_key ? loan?.frontIDImage_key : "",
          loan?._id,
          "main"
        );
      };

      reader.readAsDataURL(file);
    }
  };

  //   console.log("previewFrontCard", previewFrontCard);
  console.log("formData", formData);
  console.log("affordability", affordability);
  console.log("loanAmount", loanAmount);

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });

    console.log("formData.loanAmount", formData.loanAmount);
  };

  const handleDoBChange = (e) => {
    const today = new Date();
    const year = today.getFullYear();
    const dob = e?.target?.value.split("-")[0];

    const newAge = parseInt(year) - parseInt(dob);
    setAge(newAge);
    setDateOfBirth(e?.target?.value);
    setFormData({ ...formData, dateOfBirth: e?.target?.value, age: newAge });
  };


  useEffect(() => {
    setFormData(loan)
  },[loan])

  console.log('formData', formData)

  const INTEREST_RATE = 11 / 100; //11%

  useEffect(() => {
    if (Number(loan.loanAmount) && Number(loan?.affordability) && Number(formData?.loanAmount)) {
      const FEE_CHARGE = (3 / 100) *  Number(formData?.loanAmount);
   

      let convertedDuration = 0;
      if (Number(formData.loanTerm) === 18) {
        convertedDuration = 1.5;
      } else if (Number(formData.loanTerm) === 24) {
        convertedDuration = 2;
      } else if (Number(formData.loanTerm) === 36) {
        convertedDuration = 3;
      } else if (
        Number(formData.loanTerm) == 1 ||
        Number(formData.loanTerm) == 3 ||
        Number(formData.loanTerm) == 6 ||
        Number(formData.loanTerm) == 12
      ) {
        convertedDuration = 1;
      }

      console.log("FEE_CHARGE", FEE_CHARGE);
      console.log("INTEREST_RATE", INTEREST_RATE);

      const loanPeriod = (Number(convertedDuration) * INTEREST_RATE) + 1;
      console.log("loanPeriod", loanPeriod);


      const calc =  Number(formData?.loanAmount ) * loanPeriod;
      console.log("calc", calc);

      const initialAmount = calc + FEE_CHARGE;
      console.log("initialAmount", initialAmount);

      const monthlyDed = initialAmount / Number(formData.loanTerm);
      console.log("monthlyDed", monthlyDed);

      // const totalCollectable = initialAmount;

      setMonthlyDeduction(Number(monthlyDed).toFixed(2));

    }
  }, [formData.loanTerm, formData.loanAmount, formData?.affordability]);

  const handleFormEditSubmit = (e) => {
    e.preventDefault();
    console.log("formData", formData);
    EditLoanApplicationFormByMember(formData, loan?._id);
  };

  // const gender = user?.name?.split(" ")[0] === "Mr." ? "Male" : "Female";
  // const employer_name = user?.facility?.split(" ")[1].split(":")[0];
  useEffect(() => {
    GetUserProfile();
  }, []);
  useEffect(() => {
    GetAppliedLoanDetailed(loan_id);
  }, [loan_id]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      history.push("/my-applied-loans")
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      // GetAppliedLoanDetailed(loan_id)
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }else if(response?.state === "UPLOAD_SUCCESS"){
      toast.success(response?.message);
      window.location.reload(false);
      setTimeout(() => {
        ResetUserDataResponse();
      }, 1500);
    }
  }, [response?.state, response?.message]);

  return (
    <div className="ApplyLoans">
      <RenderHome title={"Edit Loan Application Form"}>
        {isLoading ?? <Loader />}

        <form action="" onSubmit={handleFormEditSubmit}>
          {formState === 1 && (
            <div className="form_area form1">
              <h3 className="formTitle">Personal Information</h3>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fullname">
                    Full Name<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    // name="fullname"
                    placeholder=""
                    readOnly
                    defaultValue={loan?.fullname || user?.name}
                    id="fullname"
                  />
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fullname">
                    Choose Your Region<span className="bto-lb-required">*</span>
                  </label>
                  <select
                    // name="sector"
                    className="form-control"
                    onChange={handleFormChange}
                    readOnly
                  >
                    {loan?.sector && <option>{loan?.sector} sector</option>}
                    <option value={"Southern:Greater Accra"}>
                      Greater Accra
                    </option>
                    <option value={"Southern:Eastern"}>Eastern</option>
                    <option value={"Southern:Central"}>Central</option>
                    <option value={"Southern:Bono"}>Bono</option>
                    <option value={"Southern:Bono East"}>Bono East</option>
                    <option value={"Southern:Ashanti"}>Ashanti</option>
                    <option value={"Southern:Ahafo"}>Ahafo</option>

                    <option value={"Northern:Western"}>Western</option>
                    <option value={"Northern:Western North"}>
                      Western North
                    </option>
                    <option value={"Northern:Volta"}>Volta</option>
                    <option value={"Northern:Upper West"}>Upper West</option>
                    <option value={"Northern:Upper East"}>Upper East</option>
                    <option value={"Northern:Savannah"}>Savannah</option>
                    <option value={"Northern:Oti"}>Oti</option>
                    <option value={"Northern:Northern"}>Northern</option>
                    <option value={"Northern:North East"}>North East</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="dateOfBirth">
                    Date of Birth<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    defaultValue={loan?.dateOfBirth}
                    placeholder="Date of Birth"
                    // required
                    aria-required="true"
                    onChange={(e) => handleDoBChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="age">
                    Age<span className="bto-lb-required"></span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="age"
                    defaultValue={age ?? loan?.age}
                    id="age"
                    readOnly
                    placeholder=""
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="ssnit">
                    SSNIT<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="ssnit"
                    id="ssnit"
                    placeholder=""
                    defaultValue={loan?.ssnit}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="IdCardType">
                    ID Type<span className="bto-lb-required">*</span>
                  </label>
                  <select
                    name="IdCardType"
                    id="IdCardType"
                    value={"Ghana National Identification"}
                    className="form-control"
                    onChange={(e) => handleFormChange(e)}
                  >
                    <option value="Ghana National Identification">
                      Ghana National Identification{" "}
                    </option>
                  </select>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="id_number">
                    ID Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="IDCardNumber"
                    placeholder=""
                    defaultValue={loan?.IDCardNumber}
                    id="id_number"
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="gender">
                    Gender<span className="bto-lb-required">*</span>
                  </label>
                  <select
                    name="gender"
                    id="gender"
                    defaultValue={loan?.gender}
                    onChange={(e) => handleFormChange(e)}
                    className="form-control"
                  >
                    <option>{loan?.gender}</option>
                    {/* {loan?.gender == "Female" ? (
                      <option value="Male">Male</option>
                    ) : (
                      <option value="Female">Female</option>
                    )} */}


                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>


                  </select>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Residential Address
                    <span className="bto-lb-required">*</span>
                  </label>

                  <input
                    className="form-control"
                    type="text"
                    name="residential_address1"
                    id="residential_address1"
                    defaultValue={loan?.residential_address1}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <input
                    className="form-control"
                    type="text"
                    name="residential_address2"
                    id="residential_address2"
                    aria-required="true"
                    defaultValue={loan?.residential_address2}
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="work_address1">
                    Work Address<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control form-group"
                    type="text"
                    name="work_address1"
                    id="work_address1"
                    defaultValue={loan?.work_address1}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <input
                    className="form-control form-group"
                    type="text"
                    name="work_address2"
                    defaultValue={loan?.work_address2}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>

              {/* THE CONTACT DETAILS SECTION */}
              <h3 className="formTitle">Contact Details</h3>

              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label>
                    Phone Number<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="personal_phone"
                    defaultValue={loan?.personal_phone}
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <label>
                    Work Phone<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={loan?.work_phone}
                    name="work_phone"
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <label>
                    Email Address<span className="bto-lb-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    // name="email"
                    defaultValue={loan?.email}
                    // placeholder="example@gmail.com"
                    readOnly
                    aria-required="true"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </div>

              <div className="form_area form2">
                <div className="form-group row">
                  <h3 className="formTitle">Guarantors 1</h3>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Name<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_name"
                      defaultValue={
                        loan?.guarantor_name
                      }

                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Staff ID<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_staff_id"
                      defaultValue={
                        loan?.guarantor_staff_id
                      }

                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Phone Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"

                      name="guarantor_phone"
                      placeholder=""
                      defaultValue={
                        loan?.guarantor_phone
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Ghana Card Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_ghana_card"
                      defaultValue={
                        loan?.guarantor_ghana_card
                      }

                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      GPS Address<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_gps"

                      defaultValue={
                        loan?.guarantor_gps
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Occupation
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="guarantor_occupation"
                      defaultValue={
                        loan?.guarantor_occupation
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Mandate Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_mandate_number"
                      defaultValue={
                        loan?.guarantor_mandate_number
                      }

                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      OTP Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"

                      name="guarantor_otp_number"
                      defaultValue={
                        loan?.guarantor_otp_number
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Relationship
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"

                      name="guarantor_relationship"
                      defaultValue={
                        loan?.guarantor_relationship
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                </div>
                {/* SECOND GUARANTOR SECTION */}
                <div className="form-group row">
                  <h3 className="formTitle">Guarantors 2</h3>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Name<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_name2"
                      defaultValue={
                        loan?.guarantor_name2
                      }

                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Staff ID<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_staff_id2"
                      defaultValue={
                        loan?.guarantor_staff_id2
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Phone Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"

                      name="guarantor_phone2"
                      placeholder=""
                      defaultValue={
                        loan?.guarantor_phone2
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Ghana Card Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_ghana_card2"
                      defaultValue={
                        loan?.guarantor_ghana_card2
                      }

                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      GPS Address<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_gps2"

                      defaultValue={
                        loan?.guarantor_gps2
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Occupation
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"

                      name="guarantor_occupation2"
                      defaultValue={
                        loan?.guarantor_occupation2
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Mandate Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="guarantor_mandate_number2"
                      defaultValue={
                        loan?.guarantor_mandate_number2
                      }

                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      OTP Number<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="guarantor_otp_number2"
                      defaultValue={
                        loan?.guarantor_otp_number2
                      }

                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label>
                      Relationship
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"

                      name="guarantor_relationship2"
                      defaultValue={
                        loan?.guarantor_relationship2
                      }
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />

                  </div>
                </div>

                {/* THE CONTACT DETAILS SECTION */}

                <div className="form-group row">
                  <h3 className="formTitle">Employment Details</h3>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>
                      Employer<span className="bto-lb-required">*</span>
                    </label>

                    <select
                      className="form-control"
                      type="text"
                      name="employer_name"
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option>{loan?.employer_name}</option>
                      <option value={"MOH"}>MOH</option>
                      <option value={"GHS"}>GHS</option>
                      <option value={"CHAG"}>CHAG</option>
                    </select>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label>
                      Facility<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="facility"
                      required
                      readOnly
                      value={loan?.facility}
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                    <label>
                      Staff ID<span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="staff_id"
                      readOnly
                      value={loan?.staff_id}
                      required
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                    <label>
                      Employment Start Date
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="employment_start_date"
                      defaultValue={loan?.employment_start_date}
                      required
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="form_area form3">
                <div className="form-group row">
                  <h3 className="formTitle">
                    Loan (please provide your loan details)
                  </h3>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                    <label htmlFor="loanAmount">
                      Loan Amount &#8373;
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="loanAmount"
                      id="loanAmount"
                      defaultValue={loan?.loanAmount}
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                    <label htmlFor="affordability">
                      Afordability &#8373;
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="affordability"
                      defaultValue={loan?.affordability}
                      id="affordability"
                      aria-required="true"
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Loan Terms (Months) (selected: <span className="bg-green-500 text-white px-2 rounded-sm">{loan?.loanTerm}</span>)
                        <span className="bto-lb-required">*</span>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="flex justify-evenly"
                      >
                        <FormControlLabel
                          value="6"
                          control={<Radio />}
                          label="6"
                          name="loanTerm"
                          onChange={(e) => {
                            handleFormChange(e);
                            setLoanTerm(e.target.value);
                          }}
                        />
                        <FormControlLabel
                          value="12"
                          control={<Radio />}
                          label="12"
                          name="loanTerm"
                          onChange={(e) => {
                            handleFormChange(e);
                            setLoanTerm(e.target.value);
                          }}
                        />
                        <FormControlLabel
                          value="18"
                          control={<Radio />}
                          label="18"
                          name="loanTerm"
                          onChange={(e) => {
                            handleFormChange(e);
                            setLoanTerm(e.target.value);
                          }}
                        />
                        <FormControlLabel
                          value="24"
                          control={<Radio />}
                          label="24"
                          name="loanTerm"
                          onChange={(e) => {
                            handleFormChange(e);
                            setLoanTerm(e.target.value);
                          }}
                        />

                        <FormControlLabel
                          value="36"
                          // disabled
                          name="loanTerm"
                          control={<Radio />}
                          label="36"
                          onChange={(e) => {
                            handleFormChange(e);
                            setLoanTerm(e.target.value);
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2"></div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="monthlyDeduction">
                      Monthly Deduction &#8373;
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="monthlyDeduction"
                      id="monthlyDeduction"
                      required
                      value={
                        monthlyDeduction
                          ? monthlyDeduction
                          : loan?.monthlyDeduction
                      }
                      readOnly
                      aria-required="true"
                      onChange={(e) => {
                        handleFormChange(e);
                        setLoanAmount(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label htmlFor="loantotalCollectable">
                      Mandate Number
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="mandateNumber"
                      id="mandateNumber"
                      defaultValue={loan?.mandateNumber}
                      required
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label htmlFor="otpNumber">
                      OTP Number
                      <span className="bto-lb-required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="otpNumber"
                      id="otpNumber"
                      defaultValue={loan?.otpNumber}
                      required
                      aria-required="true"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>

                  {/* Drop purpose of loan here */}
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <label htmlFor="purposeForLoan">
                      Purpose of loan
                      <span className="bto-lb-required"></span>
                    </label>
                    <textarea
                      className="form-control"
                      name="purposeForLoan"
                      defaultValue={loan?.purposeForLoan}
                      id="purposeForLoan"
                      onChange={(e) => handleFormChange(e)}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_account_holder_name">
                          Name of Account Holder{" "}
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="bank_account_holder_name"
                          id="bank_account_holder_name"
                          defaultValue={loan?.bank_account_holder_name}
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_name">
                          Name of Bank{" "}
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="bank_name"
                          defaultValue={loan?.bank_name}
                          required
                          id="bank_name"
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_account_number">
                          Account Number{" "}
                          <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="bank_account_number"
                          id="bank_account_number"
                          defaultValue={loan?.bank_account_number}
                          required
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="bank_branch">
                          Branch <span className="bto-lb-required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="bank_branch"
                          defaultValue={loan?.bank_branch}
                          required
                          id="bank_branch"
                          aria-required="true"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>
                  </>
                </div>

                <div className="form_area form4">
                  <h3 className="formTitle">Upload ID card</h3>
                  <div className="description">
                    Only Ghana National Identification card is accept. Other Id
                    cards will be rejected.
                  </div>
                  <div className="card_container">
                    <div className="image-card">
                      <img
                        src={
                          previewFrontCard ||
                          (loan?.frontIDImage ?? loan?.frontIDImage+'?'+timestamp)
                        }
                        alt="front-id"
                      />
                    </div>
                    <div className="labelAndInput">
                      <div className="label">Upload front card</div>
                      <input
                        type={"file"}
                        name="frontCard"
                        onChange={(e) => handleIDCardChange(e)}
                      />
                    </div>
                  </div>

                  <div className="card_container">
                    <div className="image-card">
                      <img
                        src={
                          previewPassportImage ||
                          (loan?.passportImage ?? loan?.passportImage+'?'+timestamp)
                        }
                        alt="Passport Photo"
                      />
                    </div>
                    <div className="labelAndInput">
                      <div className="label">Upload Back</div>
                      <input
                        type={"file"}
                        name="passport"
                        onChange={(e) => handlePassportChange(e)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <button
                      className="button_cancel backBtn"
                      // type="button"
                      onClick={() => history.push("/my-applied-loans")}
                    >
                      Back
                    </button>
                    <button
                      className="button"
                      // type="button"
                      onClick={(e) => {
                        handleFormSubmit(e);
                      }}
                    >
                      Save and re-submit
                    </button>
                  </div>
                </div>
              </div>

              {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "1rem",
                  }}
                >
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    {" "}
                    Re-submit Form
                  </button>
                  
                </div> */}
            </div>
          )}
        </form>
      </RenderHome>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default ApplyLoans;